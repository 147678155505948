import styled, { css } from 'styled-components'

export const ContainerNotFound = styled.div<{ $fullHeight: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  ${({ $fullHeight }) =>
    $fullHeight &&
    css`
      height: 100vh;
    `}

  background-color: ${({ theme }) => theme.colors.black};

  > .ant-result > .ant-result-title {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ theme }) => theme.font.sizes.xxlarge};
    font-weight: ${({ theme }) => theme.font.bold};
  }

  > .ant-result > .ant-result-subtitle {
    color: ${({ theme }) => theme.colors.gray400};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ theme }) => theme.font.sizes.large};
    font-weight: ${({ theme }) => theme.font.normal};
  }

  path[d='M174.823 30.03c0-16.281 13.198-29.48 29.48-29.48 16.28 0 29.48 13.199 29.48 29.48 0 16.28-13.2 29.48-29.48 29.48-16.282 0-29.48-13.2-29.48-29.48'] {
    fill: ${({ theme }) => theme.colors.blue};
  }
`

export const FooterResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
