'use client'

import { useRouter } from 'next/navigation'
import { Result } from 'antd'

import { ButtonUI } from '@/components/UI/ButtonUI'

import * as S from './styles'

interface IProps {
  fullHeight?: boolean
  subTitle?: string
}

export function NotFoundComponent({
  fullHeight = true,
  subTitle = 'Desculpe, a página que você visitou não existe.'
}: IProps) {
  const router = useRouter()
  return (
    <S.ContainerNotFound $fullHeight={fullHeight}>
      <Result
        status="404"
        title="404"
        subTitle={subTitle}
        extra={
          <S.FooterResult>
            <ButtonUI onClick={() => router.push('/')} size="medium">
              Ir para a página inicial
            </ButtonUI>
          </S.FooterResult>
        }
      />
    </S.ContainerNotFound>
  )
}
