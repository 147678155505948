import React from 'react'

import * as S from './styles'

import { LoadingOutlined } from '@ant-design/icons'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'huge' | 'none'
  iconOnly?: boolean
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'danger'
  children?: React.ReactNode
  iconRN?: React.ReactNode
  active?: boolean
  loading?: boolean
  disable?: boolean
}

export function ButtonUI({
  size = 'small',
  iconOnly = false,
  iconRN,
  buttonType = 'primary',
  active = false,
  loading = false,
  disable = false,
  children,
  ...props
}: ButtonProps) {
  return (
    <S.StyledButton
      $iconOnly={iconOnly}
      $size={size}
      $buttonType={buttonType}
      $active={active}
      $loading={loading}
      $disable={disable}
      {...props}
    >
      {!!iconRN && !loading && (
        <S.Icon
          $iconOnly={iconOnly}
          className={`icon-btn ${iconOnly ? 'only-icon' : ''}`}
        >
          {iconRN}
        </S.Icon>
      )}
      {loading ? (
        <LoadingOutlined style={{ fontSize: '13px' }} spin />
      ) : (
        children || <></>
      )}
    </S.StyledButton>
  )
}
