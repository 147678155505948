import styled, { css } from 'styled-components'

interface StyledButtonProps {
  $size: 'small' | 'medium' | 'huge' | 'none'
  $iconOnly: boolean
  $buttonType: 'primary' | 'secondary' | 'tertiary' | 'danger'
  $active: boolean
  $loading: boolean
  $disable: boolean
}

const sizeVariants = {
  none: css``,
  small: css`
    padding: 8px 12px;
    font-size: 1.75rem;
  `,
  medium: css`
    padding: 12px 16px;
    font-size: 2rem;
  `,
  huge: css`
    padding: 16px 24px;
    font-size: 2rem;
  `
}

const buttonTypeVariants = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.greenDark};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background-color: ${({ theme }) => theme.colors.greenLight};
    }
  `,
  secondary: css`
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.blueLight};
    &:hover {
      color: ${({ theme }) => theme.colors.primary100};
      .icon-btn.only-icon {
        color: ${({ theme }) => theme.colors.blue};
      }
    }
    &:active {
      color: ${({ theme }) => theme.colors.blue};
    }
  `,
  tertiary: css`
    background-color: ${({ theme }) => theme.colors.marine};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background-color: ${({ theme }) => theme.colors.marineBright};
      color: ${({ theme }) => theme.colors.white};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.marine};
      color: ${({ theme }) => theme.colors.blue};
    }
  `,
  danger: css`
    background-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
      background-color: ${({ theme }) => theme.colors.dangerBright};
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.dangerDark};
    }
  `
}

export const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  cursor: pointer;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 4px;

  font-weight: ${({ theme }) => theme.font.bold};
  font-size: ${({ theme }) => theme.font.sizes.xlarge};

  box-shadow: 0px 0px 8px 0px #00000033;

  ${({ $size }) => sizeVariants[$size]};
  ${({ $buttonType }) => buttonTypeVariants[$buttonType]};

  ${({ $iconOnly, theme }) =>
    $iconOnly &&
    css`
      width: 45px;
      height: 45px;
      .icon-btn {
        font-size: ${theme.font.sizes.xlarge};
      }
    `}

  border-radius: 4px;

  ${({ theme, $active, $buttonType }) =>
    $active &&
    css`
      pointer-events: none;
      background-color: ${theme.colors.blueLight};
      color: ${$buttonType === 'tertiary'
        ? theme.colors.marineBright
        : theme.colors.black};
    `}

  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      opacity: 0.9;
    `}

  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.colors.marine};
      color: ${({ theme }) => theme.colors.black};
    `};

  transition: all 200ms ease-in-out;
`

export const Icon = styled.span<{
  $iconOnly: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 200ms ease-in;
  font-size: 13px;
  ${({ $iconOnly }) =>
    !$iconOnly &&
    css`
      margin-right: 8px;
    `}
`
